<template>
  <section class="seccao seccao-promo-code">
    <header class="bg-black">
      <div class="header-coluna-esq">
        <h2 class="title-with-strap white">Destaques</h2>
        <div class="breadcrumb-wrapper">
          <div class="breadcrumb">
            <router-link to="/home" class="bcRouter"> Home </router-link>
          </div>
        </div>
      </div>
      <div class="header-coluna-dir">
        <div class="texto-font-2 white">Emotional Technology</div>
        <!-- <div class="title-with-strap white">Guide</div> -->

        <div v-if="user_name" @click="updateUserShow()">
          <i id="icon-user-header" class="far fa-user"></i>
        </div>
        <div v-if="!user_name" @click="loginForm()">
          <i id="icon-user-header" class="far fa-user"></i>
        </div>
      </div>
    </header>
    <Area />
    <main id="destMain">
      <div class="main-inner-wrapper">
        <div class="main-coluna-conteudo sem-padding">
          <div
            id="text-body"
            class="main-coluna-conteudo-inner-wrapper margin-top-25p"
          >
            <h2 class="h2 title-with-strap">
              {{ curr_prod.name }}
            </h2>

            <div
              v-if="curr_prod.g_product_id == '26'"
              class="font-size-22 font-weight-600"
            >
              <span
                class="font-size-26"
                style="text-decoration: line-through; color: #666"
                >{{
                  parseInt(curr_prod.price)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}€</span
              >
              <div class="h2" style="color: var(--cor-2); font-weight: bold">
                {{
                  parseInt(curr_prod.discount_price)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}€
              </div>

              <p>
                {{ curr_prod.frase }} <br />
                <small style="color: rgb(167, 169, 172); font-size: 70%"
                  >(acresce o IVA à taxa legal em vigor)</small
                >
              </p>
            </div>

            <div
              v-else-if="curr_prod.frase == null"
              class="font-size-22 font-weight-600"
            >
              <span
                class="font-size-26"
                style="text-decoration: line-through; color: #666"
                >{{
                  parseInt(curr_prod.price)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}€</span
              >
              <div class="h2" style="color: var(--cor-2); font-weight: bold">
                {{
                  parseInt(curr_prod.discount_price)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}€
              </div>

              <p>
                Comemore connosco este momento com preços incríveis! <br />
                <small style="color: rgb(167, 169, 172); font-size: 70%"
                  >(acresce o IVA à taxa legal em vigor)</small
                >
              </p>
            </div>

            <div class="font-size-22 font-weight-600" v-else>
              <div class="h3" style="color: var(--cor-2); font-weight: bold">
                {{
                  parseInt(curr_prod.price)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}€
              </div>
              <span class="font-size-22" style="color: #666">{{
                curr_prod.frase
              }}</span>

              <p>
                Comemore connosco este momento com preços incríveis! <br />
                <small style="color: rgb(167, 169, 172); font-size: 70%"
                  >(acresce o IVA à taxa legal em vigor)</small
                >
              </p>
            </div>

            <div class="button-wrapper margin-top-50">
              <router-link
                :to="{
                  path:
                    '/produto/' +
                    curr_prod.db_product_id +
                    '-' +
                    curr_prod.db_product_id.replaceAll(' ', '-').toLowerCase(),
                }"
              >
                <a href="" class="button black" id="chamamento"
                  >Saiba mais sobre este produto</a
                >
              </router-link>
              <router-link
                :to="{ path: '/formulario/' + curr_prod.db_product_id }"
              >
                <a href="" class="button black" id="acao">Solicitar Contacto</a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="main-coluna-imagens">
          <div class="main-coluna-imagens-inner-wrapper">
            <div class="imagens-wrapper swiper-wrapper">
              <router-link
                :to="{
                  path:
                    '/produto/' +
                    curr_prod.db_product_id +
                    '-' +
                    curr_prod.db_product_id.replaceAll(' ', '-').toLowerCase(),
                }"
                v-for="item in destaques.page"
                :key="'a_' + item.g_product_id"
                :prod-id="item.g_product_id"
                :class="'swiper-slide prod-' + item.type"
                :style="{
                  'background-image':
                    'url(/assets/fotos_catalogo/products/' +
                    item.img_carrossel +
                    ')',
                }"
              >
                <video
                  v-if="item.type == 'video'"
                  class="prod-video"
                  autoplay
                  loop
                  muted
                >
                  <source
                    :src="
                      '/assets/fotos_catalogo/products/' + item.img_carrossel
                    "
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </router-link>
            </div>

            <div class="swiper-container gallery-thumbs">
              <div class="swiper-wrapper">
                <div
                  v-for="item in destaques.page"
                  :key="'b_' + item.g_product_id"
                  :class="'swiper-slide thumb-' + item.type"
                  :style="{
                    'background-image':
                      'url(/assets/fotos_catalogo/products/' +
                      item.img_carrossel +
                      ')',
                  }"
                >
                  <span v-if="item.type == 'video'"
                    ><i class="far fa-play-circle"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer-contactos">
      <div id="destFooter" class="footer-coluna-100p">
        <div class="flex-row align-center justify-space-between">
          <div class="container-contactos flex-row align-center">
            <div class="contacto-footer-item morada">
              <span class="icone-contacto"
                ><img src="/assets/imagens/icone_morada.svg" alt="icone morada"
              /></span>
              <span class="texto-contacto"
                >Beloura Office Park Edf. 10, 2710-693 Sintra</span
              >
            </div>
            <div class="contacto-footer-item telefone">
              <span class="icone-contacto"
                ><img
                  src="/assets/imagens/icone_telefone.svg"
                  alt="icone telefone"
              /></span>
              <span class="texto-contacto"
                ><a href="tel:+351219100200">+351 219 100 200</a></span
              >
            </div>
            <div class="contacto-footer-item fax">
              <span class="icone-contacto"
                ><img src="/assets/imagens/icone_fax.svg" alt="icone fax"
              /></span>
              <span class="texto-contacto"
                ><a href="tel:+351219100299">+351 219 100 299</a></span
              >
            </div>
            <div class="contacto-footer-item emaial">
              <span class="icone-contacto"
                ><img src="/assets/imagens/icone_email.svg" alt="icone email"
              /></span>
              <span class="texto-contacto"
                ><a href="mailto:info@glintt.com">info@glintt.com</a></span
              >
            </div>
            <div class="contacto-footer-item emaial">
              <span class="icone-contacto"
                ><img src="@/assets/imagens/icone_phone.svg" alt=""
              /></span>
              <span class="texto-contacto"
                ><a>Assistência Técnica: 808 200 419</a></span
              >
            </div>
          </div>
          <div class="logotipo-footer">
            <img
              src="/assets/imagens/glintt_logo_footer.svg"
              alt="logotipo Glintt"
            />
          </div>
        </div>
        <div class="text-center">
          Copyright {{ getFullYear() }} | Boosted by
          <a href="https://byd.pt" target="_blank">BYD</a>
        </div>
      </div>
    </footer>

    <div class="loadingLayer" :style="'display: ' + loadingStyle">
      <img src="/assets/imagens/loading.gif" />
      <h3>A carregar...</h3>
    </div>
  </section>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  import Area from "@/components/Area.vue";

  export default {
    name: "destaques",
    components: {
      Area,
    },
    data() {
      return {
        destaques: {},
        curr_prod: {},
        loadingStyle: "flex",
        user_name: window.sessionStorage.getItem("name"),
      };
    },
    methods: {
      getFullYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
      },
      loginForm: async function () {
        document.querySelector("#area-screen").style.display = "block";

        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
          document.querySelector("#icon-user-header").style =
            "color: #EA8822 !important;";
        }, 200);
      },
      launch: async function () {
        var operation = "destaques";
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        var destaques = await this.call_api_get(url, headers);

        this.destaques = destaques;
        this.curr_prod = this.destaques.page[0];

        for (var i in destaques.page) {
          let e = destaques.page[i].img_carrossel.split(".").pop();
          if (e == "mp4") {
            destaques.page[i].type = "video";
          } else {
            destaques.page[i].type = "image";
          }
        }

        this.loadingStyle = "none";

        // document.querySelector(".loadingLayer").style.display = "none"
      },
      updateText: async function (prod_id) {
        var a = this.destaques.page;
        var produtoFinal = [];
        for (var prod in a) {
          produtoFinal.push(a[prod]);
        }
        var prodInfo = produtoFinal.filter((p) => {
          return p.g_product_id === prod_id;
        });

        this.curr_prod = prodInfo[0];

        console.log(this.curr_prod);
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      updateUserShow: async function () {
        console.log(document.querySelector("#area-screen"));
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#novo-nome").value = window.sessionStorage.name;
        document.querySelector("#novo-email").value =
          window.sessionStorage.email;

        document.querySelector("#area-screen").style.display = "block";
        document.querySelector(".home-section-1-bg").className =
          "home-section-1-bg promo-top";

        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
          document.querySelector("#icon-user-header-home").style =
            "color: #EA8822 !important;";
        }, 200);
      },
    },
    created: function () {
      this.launch();
    },
    mounted() {
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true

      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }
      var sys = this;

      var galleryThumbs = new Swiper(".gallery-thumbs", {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });

      $(".imagens-wrapper").each(function () {
        var $imagensWrapper = $(this);

        if ($imagensWrapper.children().length > 1) {
          $(this).parent().addClass("swiper-container");
          //Slider
          var mySwiper = new Swiper("main .swiper-container", {
            slidesPerView: 1,
            slidesPerGroup: 1,
            pagination: {
              el: ".swiper-pagination",
            },
            navigation: {
              nextEl: ".slider-swiper-button-next",
            },
            on: {
              slideChange: function () {
                var currentSlide = this.activeIndex;
                var prod_id = this.slides[currentSlide].getAttribute("prod-id");
                sys.updateText(prod_id);
              },
            },

            thumbs: {
              swiper: galleryThumbs,
            },
          });
        }
      });
    },
    updated() {
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true

      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }

      var sys = this;

      var galleryThumbs = new Swiper(".gallery-thumbs", {
        spaceBetween: 5,
        slidesPerView: 8,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });

      $(".imagens-wrapper").each(function () {
        var $imagensWrapper = $(this);

        if ($imagensWrapper.children().length > 1) {
          $(this).parent().addClass("swiper-container");
          //Slider
          var mySwiper = new Swiper("main .swiper-container", {
            slidesPerView: 1,
            slidesPerGroup: 1,
            fadeEffect: {
              crossFade: true,
            },
            pagination: {
              el: ".swiper-pagination",
            },
            navigation: {
              nextEl: ".slider-swiper-button-next",
            },
            on: {
              slideChange: function () {
                var currentSlide = this.activeIndex;
                var prod_id = this.slides[currentSlide].getAttribute("prod-id");
                sys.updateText(prod_id);
              },
            },
            thumbs: {
              swiper: galleryThumbs,
            },
          });
        }
      });
    },
    beforeRouteLeave(to, from, next) {
      document.querySelector(".loadingLayer").style.display = "flex";
      next();
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  #destMain {
    height: calc(99vh - 185px);
  }
  .button-wrapper {
    width: 75%;
    overflow: hidden;
    -webkit-transform: skew(-15deg);
    -moz-transform: skew(-15deg);
    -o-transform: skew(-15deg);
    transform: skew(-15deg);
    border: 1px solid #000;
  }
  #icon-user-header-home {
    margin-left: 30px;
    font-size: 2.8vh !important;
    margin-right: -20px !important;
    display: block !important;
    color: #fff !important;
  }
  #icon-user-header-home:hover {
    cursor: pointer;
  }

  #acao {
    width: 100%;
    text-align: center;
    font-size: 2.1vh !important;
    background: #fff;
    color: #000000;
    display: inline-block;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-transform: skew(15deg);
    -moz-transform: skew(15deg);
    -o-transform: skew(15deg);
    transform: skew(15deg);
  }
  #chamamento {
    width: 110%;
    color: #ffffff;
    margin-bottom: 0px;
    font-weight: bold;
    text-align: center;
    font-size: 2.1vh !important;
    color: #fff;
    background: #000000;
    display: inline-block;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-transform: skew(15deg);
    -moz-transform: skew(15deg);
    -o-transform: skew(15deg);
    transform: skew(15deg);
    margin-left: -5%;
  }
  #acao:hover,
  #chamamento:hover {
    cursor: pointer;
    font-weight: 800;
  }

  .seccao-promo-code main .main-inner-wrapper .main-coluna-conteudo {
    width: 32vw;
  }
  .seccao-promo-code main .main-inner-wrapper .main-coluna-imagens {
    width: 68vw;
    position: relative;
  }
  .slider-swiper-button-next {
    background-image: url("/assets/imagens/arrow_next_gray.svg");
  }

  .seccao-promo-code main {
    height: calc(99vh - 178px);
  }

  .home-section-menu-fixo main .main-inner-wrapper .main-coluna-conteudo,
  .seccao-promo-code main .main-inner-wrapper .main-coluna-conteudo {
    padding-top: 0px;
  }
  #text-body {
    align-items: flex-start;
  }
  .gallery-thumbs {
    bottom: 30%;
    z-index: 3;
  }
  footer.footer-contactos {
    height: auto;
  }
  #destFooter {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    max-height: 9vh;
  }
  .gallery-thumbs .swiper-slide {
    max-width: 100px;
    max-height: 100px;
  }

  .seccao main .main-inner-wrapper .main-coluna-conteudo::after {
    display: none;
  }

  .thumb-video {
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thumb-video i {
    color: #fff;
    font-size: 40px;
  }

  @media only screen and (max-height: 768px) and (orientation: landscape) {
    /* #chamamento {
            margin-left: 9px;
        } */
    #destMain {
      height: calc(99vh - 175px);
    }
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    .promo-top {
      margin-top: calc(50px + 17px + 6.75vh) !important;
    }
  }

  @media only screen and (max-width: 640px) {
    #destMain {
      height: auto;
    }
    .seccao-promo-code main .main-inner-wrapper .main-coluna-conteudo {
      width: 100vw;
    }

    .seccao header {
      flex-flow: column;
    }

    .seccao header .header-coluna-esq,
    .seccao header .header-coluna-dir {
      width: 100%;
      justify-content: flex-start;
    }

    .seccao header .header-coluna-dir {
      margin-top: 10px;
    }

    /* #acao, #chamamento {
            -webkit-transform: unset;
            -moz-transform:unset;
            -o-transform:unset;
            transform:unset;
            margin: unset;
            width: 80%;
        }

        .button-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        } */

    .seccao-promo-code main .main-inner-wrapper .main-coluna-imagens {
      width: 100%;
    }
    .seccao-promo-code
      main
      .main-inner-wrapper
      .main-coluna-imagens
      .main-coluna-imagens-inner-wrapper:before {
      display: none;
    }

    .prod-video {
      max-height: 100%;
      height: auto;
      width: 100%;
      align-self: center;
    }

    #icon-user-header-home {
      margin-right: 0px !important;
      position: absolute;
      color: #fff !important;
      top: 15px;
      right: 15px;
    }
  }
</style>

<style>
  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    .promo-top {
      margin-top: calc(50px + 17px + 6.75vh) !important;
    }
  }
</style>
